import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactTooltip from 'react-tooltip';

export default function FormContainer({ onFormSubmit }) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        onFormSubmit(data)
    };

    return (
        <><div class="inputForm"><form onSubmit={handleSubmit(onSubmit)}>
            <div class='item'><label for='depositAmount'>Deposit Amount ($)</label>
                <input class='input' size="20" defaultValue={50000} {...register("depositAmount", { required: true })} />
            </div>
            <div class='item'><label for='token1'>Token 1 </label>
                <input class='input' size="20" defaultValue="DPX" {...register("token1", { required: true })} />
            </div>
            <div class='item'><label for='token1value'>Value </label>
                <input class='input' size="20" defaultValue={200} {...register("token1value", { required: true })} />
            </div>
            <div class='item'><label data-tip="Estimated price change over 1 year" for='token1priceChange'> Price Change (%) </label>
                <input class='input' size="20" defaultValue={-20} {...register("token1priceChange", { required: true })} />
            </div>
            <div class='item'><label for='token2'>Token2 </label>
                <input class='input' size="20" defaultValue="ETH" {...register("token2", { required: true })} />
            </div>
            <div class='item'><label for='token2value'>Value </label>
                <input class='input' size="20" defaultValue={1200} {...register("token2value", { required: true })} />
            </div>
            <div class='item'><label for='token2priceChange'>Price Change (%) </label>
                <input class='input' size="20" defaultValue={0} {...register("token2priceChange", { required: true })} />
            </div>
            <div class='item'><label data-tip="Advertised APR - note this can vary with reward token price changes, as well as overall liquidity increasing/decreasing" for='APR'>APR (%)</label>
                <input class='input' size="20" defaultValue={100} {...register("APR", { required: true })} />
            </div>
            <div class='item'><label data-tip="How many times you plan to claim your rewards (either to dump or compound). E.g. 12 implies compounding once per month, 52 for weekly etc" for='steps'># Compounding Steps </label>
                <input class='input' size="20" defaultValue={12} {...register("steps", { required: true })} />
            </div>
            <div class='item'><label data-tip data-for='gasCost' for='gasCostFixed'>Fixed Gas Costs to Compound </label>
                <input class='input' size="20" defaultValue={5} {...register("gasCostFixed", { required: false })} />
            </div>
            <div class='item'><label data-tip data-for='gasCost' for='gasCostVariable'>Gas Costs to Compound (% of Reward Amount) </label>
                <input class='input' size="20" defaultValue={0.15} {...register("gasCostVariable", { required: false })} />
            </div>
            <div class='item'><label data-tip data-for='gasCost' for='gasCostFixedDump'>Fixed Gas Costs to Dump </label>
                <input class='input' size="20" defaultValue={4} {...register("gasCostFixedDump", { required: false })} />
            </div>
            <div class='item'><label data-tip data-for='gasCost' for='gasCostVariableDump'>Gas Costs to Dump (% of Reward Amount) </label>
                <input class='input' size="20" defaultValue={0.6} {...register("gasCostVariableDump", { required: false })} />
            </div>
            <br></br>
            <button class="button-36" name="name" value="value" type="submit">Go!</button>
        </form>
        </div>
            <ReactTooltip type='light' />
        </>
    );
}