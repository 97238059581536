import * as React from 'react';
import { useState } from 'react';

import Layout from '../components/layout.js';
import ResultTable from '../components/ResultTable.js';
import FormContainer from '../containers/FormContainer.js';

export default function CalculatorPage() {

    const [tableInfo, setTableInfo] = useState([]);

    const onFormSubmit = (formInfo) => {
        console.log(formInfo)
        buildSim(formInfo)
    }

    const buildSim = function (info) {

        const getFactors = function (info) {
            let endPrice = info.token1value * (1 + (info.token1priceChange / 100))
            let num = endPrice / info.token1value;
            let denom = 1 / info.steps
            let token1factor = Math.pow(num, denom) - 1
            let endPrice2 = info.token2value * (1 + (info.token2priceChange / 100))
            let token2factor = Math.pow(endPrice2 / info.token2value, 1 / info.steps) - 1
            let apy = getApyFromAPR(info.APR, info.steps)
            let apyFactor = Math.pow((apy + 1), denom) - 1
            // console.log(apyFactor)

            return ({ token1factor: token1factor, token2factor: token2factor, apyFactor: apyFactor })
        }

        const getApyFromAPR = function (apr, steps) {
            let num = steps;
            let apr_given = apr;
            let apy = ((1 + (apr_given / 100 / num)) ** (num)) - 1;
            // console.log('apy', apy)
            return apy
        }

        let factors = getFactors(info)

        const buildTable = function (info, factors) {
            let tableInfo = []
            for (let i = -1; i < info.steps; i++) {
                // console.log('tableInfo', i, tableInfo)
                let row;
                if (tableInfo.length) {
                    let len = tableInfo.length;
                    // console.log('current length of tableinfo ', len)
                    row = buildRow(info, factors, i + 1, tableInfo[len - 1]);
                } else {
                    row = buildRow(info, factors, i + 1, null)
                }
                if (row.length) {
                    tableInfo.push(row)
                }
            }
            return tableInfo
        }

        const buildRow = function (info, factors, i, lastLine) {
            // console.log('buildRow', info, factors)
            // console.log(i, lastLine)
            // console.log('lastLine ', lastLine)
            let rowInfo;
            if (lastLine == null) {
                let dpxStartAmount = (info.depositAmount / 2) / info.token1value;
                let dpxPrice = info.token1value
                let dpx$Value = dpxStartAmount * dpxPrice
                let ethPrice = info.token2value;
                let ethStartAmount = (info.depositAmount / 2) / info.token2value;
                let eth$Value = ethPrice * ethStartAmount
                let poolValue = eth$Value + dpx$Value


                rowInfo = [{
                    step: 0,
                    dpxPrice: dpxPrice,
                    dpxStartAmount: dpxStartAmount,
                    dpx$Value: dpx$Value,
                    ethPrice: ethPrice,
                    ethStartAmount: ethStartAmount,
                    eth$Value: eth$Value,
                    poolValue: poolValue,
                    pool$ValueLossGain: 0,
                    dpxLossGain: 0,
                    ethLossGain: 0,
                    dpx$ValueLossGain: 0,
                    eth$ValueLossGain: 0,

                    D_farming$Rewards: 0,
                    D_farming$Rewards_cumulative: 0,
                    D_totalValue: poolValue,

                    C_poolValueStart: poolValue,
                    C_gross$FarmingRewards: 0,
                    C_fees: 0,
                    C_net$FarmingRewards: 0,
                    C_poolValueEnd: poolValue,
                    C_dpxAmountStart: dpxStartAmount,
                    C_ethAmountStart: ethStartAmount,
                    C_dpxAmountEnd: dpxStartAmount,
                    C_ethAmountEnd: ethStartAmount,
                    C_dpx$ValueStart: dpx$Value,
                    C_eth$ValueStart: eth$Value,
                    C_pool$LossGain: 0,
                    C_dpx$LossGain: 0,
                    C_eth$LossGain: 0,
                    C_dpxLossGain: 0,
                    C_ethLossGain: 0
                }]
            } else {
                let thisStep = i;
                let dpxPrice = (lastLine[0].dpxPrice) * (1 + factors.token1factor);
                let dpxStartAmount = lastLine[0].dpxStartAmount + lastLine[0].dpxLossGain;
                let dpx$Value = dpxPrice * dpxStartAmount;
                let ethPrice = (lastLine[0].ethPrice) * (1 + factors.token2factor);
                let ethStartAmount = lastLine[0].ethStartAmount + lastLine[0].ethLossGain;
                let eth$Value = ethPrice * ethStartAmount;
                let poolValue = eth$Value + dpx$Value;
                let pool$ValueLossGain = poolValue - lastLine[0].poolValue;
                let dpx$ValueLossGain = pool$ValueLossGain / 2;
                let eth$ValueLossGain = dpx$ValueLossGain;
                let dpxLossGain = (poolValue / 2 / dpxPrice) - dpxStartAmount;
                let ethLossGain = (poolValue / 2 / ethPrice) - ethStartAmount;

                let D_gross$farmingRewards = poolValue * (factors.apyFactor);
                let D_fees = Number(info.gasCostFixedDump) + (D_gross$farmingRewards * (info.gasCostVariableDump /100))
                let D_net$FarmingRewards = D_gross$farmingRewards - D_fees
                let D_farming$Rewards_cumulative = lastLine[0].D_farming$Rewards_cumulative + D_net$FarmingRewards;
                let D_totalValue = D_farming$Rewards_cumulative + poolValue;

                let C_dpxAmountStart = lastLine[0].C_dpxAmountEnd + lastLine[0].C_dpxLossGain
                let C_ethAmountStart = lastLine[0].C_ethAmountEnd + lastLine[0].C_ethLossGain
                let C_dpx$ValueStart = C_dpxAmountStart * dpxPrice
                let C_eth$ValueStart = C_ethAmountStart * ethPrice
                let C_poolValueStart = C_dpx$ValueStart + C_eth$ValueStart
                let C_pool$LossGain = C_poolValueStart - lastLine[0].C_poolValueEnd
                let C_dpx$LossGain = C_pool$LossGain / 2
                let C_eth$LossGain = C_dpx$LossGain
                let C_dpxLossGain = C_poolValueStart / 2 / dpxPrice - C_dpxAmountStart
                let C_ethLossGain = C_poolValueStart / 2 / ethPrice - C_ethAmountStart
                let C_gross$FarmingRewards = C_poolValueStart * factors.apyFactor
                let C_fees = Number(info.gasCostFixed) + (C_gross$FarmingRewards * (info.gasCostVariable /100))
                let C_net$FarmingRewards = C_gross$FarmingRewards - C_fees
                let C_poolValueEnd = C_poolValueStart + C_net$FarmingRewards
                let C_dpxAmountEnd = C_poolValueEnd / 2 / dpxPrice
                let C_ethAmountEnd = C_poolValueEnd / 2 / ethPrice

                rowInfo = [{
                    step: thisStep,
                    dpxPrice: dpxPrice,
                    dpxStartAmount: dpxStartAmount,
                    dpx$Value: dpx$Value,
                    ethPrice: ethPrice,
                    ethStartAmount: ethStartAmount,
                    eth$Value: eth$Value,
                    poolValue: poolValue,
                    pool$ValueLossGain: pool$ValueLossGain,
                    dpx$ValueLossGain: dpx$ValueLossGain,
                    eth$ValueLossGain: eth$ValueLossGain,
                    dpxLossGain: dpxLossGain,
                    ethLossGain: ethLossGain,

                    D_net$FarmingRewards: D_net$FarmingRewards,
                    D_farming$Rewards_cumulative: D_farming$Rewards_cumulative,
                    D_totalValue: D_totalValue,
                    
                    C_dpxAmountStart: C_dpxAmountStart,
                    C_ethAmountStart: C_ethAmountStart,
                    C_dpx$ValueStart: C_dpx$ValueStart,
                    C_eth$ValueStart: C_eth$ValueStart,
                    C_poolValueStart: C_poolValueStart,
                    C_pool$LossGain: C_pool$LossGain,
                    C_dpx$LossGain: C_dpx$LossGain,
                    C_eth$LossGain: C_eth$LossGain,
                    C_dpxLossGain: C_dpxLossGain,
                    C_ethLossGain: C_ethLossGain,
                    C_gross$FarmingRewards: C_gross$FarmingRewards,
                    C_fees: C_fees,
                    C_net$FarmingRewards: C_net$FarmingRewards,
                    C_poolValueEnd: C_poolValueEnd,
                    C_dpxAmountEnd: C_dpxAmountEnd,
                    C_ethAmountEnd: C_ethAmountEnd,
                }]
            }
            return rowInfo
        }

        let tableInfo = buildTable(info, factors)
        console.log('completed tableInfo ', tableInfo)
        setTableInfo(tableInfo)
    }


    return (
        <Layout
            title="DefiYieldCalculator.com"
            description='A calculator for modeling yield farm staking rewards'
        >
            <h2 class="strapline">Better Decisions Through Better Research</h2>
            <h3 class="strapline" id="titleBody">Accurately Model Pool2 Returns</h3>
            <FormContainer onFormSubmit={onFormSubmit}></FormContainer>
            <br></br>
            {tableInfo.length > 1 ? <ResultTable tableInfo={tableInfo}></ResultTable> : null}
        </Layout>
    )
}