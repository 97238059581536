import { useTable } from 'react-table';
import React from "react";

export default function ResultTable({ tableInfo }) {

    const info = tableInfo;
    const length = info.length - 1
    const lastLine = info[length]

    const data = React.useMemo(
        () => [
            {
                pool$Value: lastLine[0].poolValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                D_farming$Rewards_cumulative: lastLine[0].D_farming$Rewards_cumulative.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                D_totalValue: lastLine[0].D_totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                C_poolValueEnd: lastLine[0].C_poolValueEnd.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            },

        ],
        [lastLine]
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'Pool Value (no rewards)',
                accessor: 'pool$Value',
            },
            {
                Header: 'Total Rewards Dumped',
                accessor: 'D_farming$Rewards_cumulative',
            },
            {
                Header: 'Total Value (Dumping Rewards)',
                accessor: 'D_totalValue'
            },
            {
                Header: 'Pool Value (Rewards Compounded)',
                accessor: 'C_poolValueEnd'
            }
        ],
        []
    )

    const tableInstance = useTable({ columns, data })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (
        <>
            <><table {...getTableProps()}>
                <thead>
                    {// Loop over the header rows
                        headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column => (
                                        // Apply the header cell props
                                        <th {...column.getHeaderProps()}>
                                            {// Render the header
                                                column.render('Header')}
                                        </th>
                                    ))}
                            </tr>
                        ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        rows.map(row => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()}>
                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                </tbody>
            </table><p>To see how it works, make a copy of <a href='https://docs.google.com/spreadsheets/d/1ZJsFgEhdkVP7lM2ZeIB27azvmT1-5sz_rrGrz48EHi0/edit?usp=sharing'>this spreadsheet</a> and play around!</p></>
        </>

    )
}

